const RoleLink = () =>
    import ("@/components/RoleLink");
const Reports = () =>
    import ("@/components/Management/Reports");
const DashBoard = () =>
    import ("@/components/Management/ManagementDashborad/DashBoard");
const TotalAuthors = () =>
    import ("@/components/Management/ManagementDashborad/TotalAuthors");
const TotalBookCategories = () =>
    import ("@/components/Management/ManagementDashborad/TotalBookCategories");
const TotalBookDonationsRequest = () =>
    import ("@/components/Management/ManagementDashborad/TotalBookDonationsRequest");
const TotalBooks = () =>
    import ("@/components/Management/ManagementDashborad/TotalBooks");
    const Academic_dashboard = () =>
    import ("@/components/Management/ManagementDashborad/Academic_dashboard");
    const TotalPeriodicals = () =>
    import ("@/components/Management/ManagementDashborad/TotalPeriodicals");
    const TotalbookWithCD = () =>
    import ("@/components/Management/ManagementDashborad/TotalbookWithCD");
const TotalEmployee = () =>
    import ("@/components/Management/ManagementDashborad/TotalEmployee");
const TotalFineCollected = () =>
    import ("@/components/Management/ManagementDashborad/TotalFineCollected");
const TotalFineCollectedFilter = () =>
    import ("@/components/Management/ManagementDashborad/TotalFineCollectedFilter");
const TotalPublisher = () =>
    import ("@/components/Management/ManagementDashborad/TotalPublisher");
const TotalStudents = () =>
    import ("@/components/Management/ManagementDashborad/TotalStudents");
const TotalTitles = () =>
    import ("@/components/Management/ManagementDashborad/TotalTitles");
    const ManagementAccessionReport = () =>
    import ("@/components/Management/Reports/ManagementAccessionReport");
    const ManagementDailyTransaction = () =>
    import ("@/components/Management/Reports/ManagementDailyTransaction");
    const ManagementMemberWise = () =>
    import ("@/components/Management/Reports/ManagementMemberWise");
    const ManagementOverDueMember = () =>
    import ("@/components/Management/Reports/ManagementOverDueMember");


const routes = [{
    path: "/rolelink",
    name: "rolelink",
    component: RoleLink,
    meta: {
        breadcrumb: "Login" //crumb
    },
    children: [
        {
            path: '/total-publisher',
            name: 'total-publisher',
            component: TotalPublisher,
        },
        {
            path: '/total-fine-collected',
            name: 'total-fine-collected',
            component: TotalFineCollected,
        },
        {
            path: '/total-fine-collected-new',
            name: 'total-fine-collected-new',
            component: TotalFineCollectedFilter,
        },
        {
            path: '/total-employee',
            name: 'total-employee',
            component: TotalEmployee,
        },
        {
            path: '/total-books',
            name: 'total-books',
            component: TotalBooks,
        },
        {
            path: '/Academic-dashboard',
            name: 'Academic-dashboard',
            component: Academic_dashboard,
        },
        {
            path: '/total-periodicals',
            name: 'total-periodicals',
            component: TotalPeriodicals,
        },
        {
            path: '/TotalbookWithCD',
            name: 'TotalbookWithCD',
            component: TotalbookWithCD,
        },
        {
            path: '/total-book-donations-request',
            name: 'total-book-donations-request',
            component: TotalBookDonationsRequest,
        },
        {
            path: '/total-book-categories',
            name: 'total-book-categories',
            component: TotalBookCategories,
        },
        {
            path: '/total-authors',
            name: 'total-authors',
            component: TotalAuthors,
        },
        {
            path: '/total-students',
            name: 'total-students',
            component: TotalStudents,
        },
        {
            path: '/total-titles',
            name: 'total-titles',
            component: TotalTitles,
        },
        {
            path: '/management-dashboard',
            name: 'management-dashboard',
            component: DashBoard,
        },
        {
            path: '/management-reports',
            name: 'management-reports',
            component: Reports,
        },
        {
            path: '/management-accession-report',
            name: 'management-accession-report',
            component: ManagementAccessionReport,
        },
        {
            path: '/management-daily-transaction',
            name: 'management-daily-transaction',
            component: ManagementDailyTransaction,
        },
        {
            path: '/management-over-due',
            name: 'management-over-due',
            component: ManagementOverDueMember,
        },
        {
            path: '/management-memberwise-report',
            name: 'management-memberwise-report',
            component: ManagementMemberWise,
        },
      
    ]

}];
export default routes;
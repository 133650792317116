import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import { router } from "./router/mainRoutes";
import VueBarcode from 'vue-barcode';
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from 'vue-sweetalert2';
import JsonExcel from 'vue-json-excel'
Vue.use(VueSweetalert2);
Vue.component('downloadExcel', JsonExcel)
Vue.component('barcode', VueBarcode)
Vue.config.productionTip = false;
// axios.defaults.headers.get['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.VUE_APP_API_URL; //live or local
console.log(process.env.VUE_APP_API_URL);
//axios.defaults.baseURL = process.env.VUE_APP_API_URL_BETA //beta
axios.interceptors.request.use(config => {
    // console.log("req-int"+navigator.onLine)
    if (!navigator.onLine) {
        alert("You are offline!!!");
    }

    if (localStorage.getItem("EPS-token"))
        config.headers.common["EPS-token"] = localStorage.getItem("EPS-token");
    if (localStorage.getItem("EPS-uid"))
        config.headers.common["EPS-uid"] = localStorage.getItem("EPS-uid");
    config.headers.common["router-path"] = router.currentRoute.path;
    return config;
});

axios.interceptors.response.use(res => {
    console.log("res-url:" + res.request.responseURL);
    console.log("res-status:" + res.status);
    if (!navigator.onLine) {
        alert("You are offline!!!");
        return;
    }
   
    if(res.data.errormsg){
        if(res.data.errormsg == "ERROR404")
            {
                console.log("----------------------------------");
                console.log(res.data.error);
                console.log(res.data.stackTrace);
                console.log("----------------------------------");
                alert("Something went Wrong.");
            }
    }
   
    if (res.data.msg == "200") {
        if (res.data.token_status == "token-refreshed")
            localStorage.setItem("EPS-token", res.data.token);
    } else if (res.data.msg == "401") {
        localStorage.setItem(
            "login-page-msg",
            "You are already logged in on a different device. Please login again"
        );
        router.push("/");
    } else if (res.data.msg == "403") {
        localStorage.setItem("login-page-msg", "Access Denied!!!");
        router.push("/access-denied");
    }
    return res;
});

Vue.config.productionTip = false;

new Vue(
    {
        components: {
            'barcode': VueBarcode
          },
        
    router,
    store,
    vuetify,
    VueSweetalert2,
    render: h => h(App)
}).$mount("#app");